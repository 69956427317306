<template>
  <div class="content-page login-page">
    <img class="logo" src="../assets/images/login/logo.png" alt="" />
    <div class="login-form-box">
      <h3 class="title">用户登录</h3>
      <div class="input-box flex al-i-c">
        <Icon color="#cdced7" :size="24" type="md-person" />
        <input v-model="userName" class="login-input" placeholder="用户名" type="text" />
      </div>
      <div class="input-box flex al-i-c">
        <Icon color="#cdced7" :size="24" type="md-unlock" />
        <input v-model="password" class="login-input" placeholder="密码" autoComplete="new-password" type="password" />
      </div>
      <!-- <div class="checkbox-and-get-psd flex al-i-c ju-c-sb">
        <div class="checkbox-box">
          <Checkbox label="twitter">
            <span>记住密码</span>
          </Checkbox>
        </div>
        <div class="get-psd-box">修改密码</div>
      </div> -->
      <div class="login-btn-box" @click="loginSubmit" style="cursor: pointer">登 录</div>
    </div>
    <div class="bottom-hint">©2022 版权归智慧展厅系统管理平台所有</div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      userName: '',
      password: '',
    }
  },
  methods: {
    async loginSubmit() {
      try {
        let res = await this.$store.dispatch('handleLogin', {
          userName: this.userName,
          password: this.password,
        })
        console.log('res:', res)
        await this.$store.dispatch('getUserInfo', {
          roleId: res.data.roleId,
        })
        console.log(this.$store.state.app.permission)
        this.$router.addRoutes(this.$store.state.app.permission)
        console.log(this.$router)
        if (res.data.roleId === 4 || res.data.roleId === 5) {
          // 展厅管理员和展厅操作员自动跳转到数据分析
          this.$router.push({
            name: 'dataAnalyse',
            params: {
              id: res.data.orgId,
              hallName: res.data.orgName,
            },
          })
        } else {
          this.$router.push({
            name: this.$config.homeName,
          })
        }
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/images/login/login-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .logo {
    width: 398px;
    height: 39px;
    position: absolute;
    top: 80px;
    left: 80px;
  }
  .login-form-box {
    width: 600px;
    height: 500px;
    background-image: url(../assets/images/login/login-kuang.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 157px;
    top: calc(50% - 250px);
    .title {
      font-size: 38px;
      color: #fff;
      text-align: center;
      line-height: 128px;
    }
    .input-box {
      width: 400px;
      height: 50px;
      padding-left: 20px;
      background-image: url(../assets/images/search-input-bg.png);
      background-size: 400px 50px;
      margin: 0 auto 30px;
      .login-input {
        background-color: transparent !important;
        outline: none;
        width: 320px;
        height: 50px;
        border: 0;
        color: #fff;
        font-size: 24px;
        margin-left: 20px;
        &::placeholder {
          font-size: 18px;
        }
      }
    }
    .checkbox-and-get-psd {
      width: 400px;
      margin: 0 auto;
      padding-left: 10px;
      .checkbox-box {
        color: #fff;
      }
      .get-psd-box {
        color: #00b8ff;
      }
    }
    .login-btn-box {
      width: 400px;
      height: 50px;
      line-height: 50px;
      background-image: url(../assets/images/login/login-btn.png);
      background-size: 400px 50px;
      margin: 60px auto;
      color: #fff;
      font-size: 24px;
      text-align: center;
    }
  }
  .bottom-hint {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 20px;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
}
</style>
